import React from "react"
import { ToastContainer } from "react-toastify"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import Layout from "../components/Layout/Layout"
import ContactForm from "../components/ContactForm/ContactForm"
import "react-toastify/dist/ReactToastify.css"
import Loadable from "@loadable/component"
import ClipLoader from "react-spinners/ClipLoader";
const withSpinner = (importComponent) => Loadable(importComponent, {
  fallback: <ClipLoader color="#2e41ff" size={150} cssOverride={{ display: 'block', margin: '50px auto', borderWidth: '4px' }} aria-label="Loading Spinner" />,
});
const SeoPage = Loadable(() => import("../components/seo-page"))
const ContactFormMiddleSection = withSpinner(() => import("../components/ContactPageMiddleSection/ContactFormMiddleSection"));
const FaqSection = withSpinner(() => import("../components/FaqSection/FaqSection"));
const PartnersSection = withSpinner(() => import("../components/PartnersSection/PartnersSection"));
const Footer = withSpinner(() => import("../components/Footer/Footer"));

const ContactPage = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "Ensys Contact",
      ro: "Contact Ensys",
    },
    slug: {
      en: "Formular de contact Ensys",
      ro: "Formular de contact Ensys",
    },
  }

  function getSeoFields(field) {
    return seoFields[field][language] ?? seoFields[field].ro // return ro fields if current language does not have them
  }

  return (
    <Layout>
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("slug")} />
      <ContactForm />
      <ContactFormMiddleSection />
      <FaqSection />
      <PartnersSection />
      <Footer />
      <ToastContainer />
    </Layout>
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default ContactPage
