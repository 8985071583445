import React, { useState, useEffect } from "react"
import { Field, FormikProvider, useFormik } from "formik"
import axios from "axios"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { t } from "i18next"
import Header from "../Header/Header"
import { useContactImage } from "../../hooks/Contact/useContactImage"
import ValidationSchema from "./validationSchema"
import EnsInput from "../EnsInput/EnsInput"
import EnsButton from "../EnsButton/EnsButton"
import "./ContactForm.scss"
import { useTranslation } from "react-i18next"

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

const ContactForm = () => {
  const image = useContactImage()
  const [emailError, setEmailError] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [csrfToken, setCsrfToken] = useState("")
  const { t } = useTranslation();
  const validationSchema = ValidationSchema();
  //TODO remove conditional when .env notation works on prod
  const salesApiUrl = process.env.GATSBY_ENSYS_SALES_API ? `${process.env.GATSBY_ENSYS_SALES_API}` : 'https://sales.api.ensys.eu/api'

  if (!csrfToken) {
    setCsrfToken(
      fetch(salesApiUrl, {
        method: "GET",
        credentials: "include",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          return data
        })
    )
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
      recaptcha: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async values => {
      const csrf = await csrfToken
      const data = {
        message: values.message,
        contact: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
        },
      }
      const ensysDB = await fetch(
        `${process.env.GATSBY_ENSYS_SALES_API}/contact-form/general/save`,
        {
          method: "POST",
          credentials: "include",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "csrf-token": csrf.csrfToken,
          },
          body: JSON.stringify(data),
        }
      )
        .then(response => {
          return response.status
        })
        .catch(response => {
          toast.error(response.message, {
            icon: false,
            theme: "colored",
            position: "top-left",
            autoClose: 7000,
            pauseOnHover: false,
            draggable: false,
            toastId: "contact-toast",
          })
          formik.setSubmitting(false)
          formik.resetForm()
        })
      if (ensysDB === 200) {
        axios
          .post("/api/send-email", {
            SentFrom: "Contact Page",
            ...values,
          })
          .then(response => {
            if (response.status === 200) {
              formik.setSubmitting(false)
              toast.success(t("Mesajul a fost trimis"), {
                icon: false,
                theme: "colored",
                position: "top-left",
                autoClose: 7000,
                pauseOnHover: false,
                draggable: false,
                toastId: "contact-toast",
              })
            }
            formik.resetForm()
          })
          .catch(() => {
            toast.error(t("Am întâmpinat o eroare la trimiterea mesajului"), {
              icon: false,
              theme: "colored",
              position: "top-left",
              autoClose: 7000,
              pauseOnHover: false,
              draggable: false,
              toastId: "contact-toast",
            })
            formik.setSubmitting(false)
            formik.resetForm()
          })
      }
    },
  })

  const callCheckEmailDebounced = () => {
    if (debouncedSearchTerm && debouncedSearchTerm.length > 6) {
      axios
        .post("/api/validate-email", {
          source: "electricUp",
          email: debouncedSearchTerm,
        })
        .then(response => {
          setEmailError(t("Email invalid"))
          if (response && response.data && response.data.result) {
            if (
              response.data.result.verdict === "Valid" ||
              response.data.result.verdict === "Risky"
            ) {
              formik.setFieldError("email", null)
              setEmailError("")
            }
          }
        })
        .catch(err => console.log("email validation error-->", err))
    }
  }

  useEffect(() => {
    setSearchTerm(formik.values.email)
  }, [formik.values.email])

  // Effect for API call
  useEffect(
    () => {
      callCheckEmailDebounced()
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  )

  return (
    <div className="contact-form">
      <Header lightMode className="header-contact" />
      <div className="container">
        <div className="col-md-6 form-col">
          <h1> {t("Formular de contact")} </h1>
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <Field
                name="lastName"
                variant="text"
                label={t("Nume de familie")}
                component={EnsInput}
                id={1}
              />
              <Field
                name="firstName"
                variant="text"
                label={t("Prenume")}
                component={EnsInput}
                id={2}
              />
              <Field
                name="email"
                variant="email"
                label={t("Adresa de email")}
                component={EnsInput}
                id={3}
              />
              {emailError && formik.values.email && (
                <p
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    marginTop: "-2rem",
                    textAlign: "left",
                  }}
                >
                  {emailError}
                </p>
              )}
              <Field
                name="phone"
                variant="tel"
                label={t("Telefon")}
                component={EnsInput}
                id={4}
              />
              <Field
                name="message"
                variant="textarea"
                label={t("Mesaj")}
                component={EnsInput}
                id={4}
              />
              <ReCAPTCHA
                name="recaptcha"
                sitekey="6LeRlA0oAAAAANQZT1gzZcmdilFY-XV4974Pkz37"
                onChange={response => {
                  formik.setFieldValue("recaptcha", response)
                }}
                className="recaptcha-validation"
              />
              {formik.errors.recaptcha && formik.touched.recaptcha && (
                <p
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.recaptcha}
                </p>
              )}
              <EnsButton
                variant="primary"
                type="submit"
                className="form-button"
                disabled={!!emailError}
              >
                {t("Trimite")}
              </EnsButton>
            </form>
          </FormikProvider>
        </div>
      </div>
      <img
        className="finalize-img"
        src={image.imagine.sourceUrl}
        alt="ensys contact"
      />
    </div>
  )
}

export default ContactForm
