import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "react-i18next"

export const useContactImage = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Contact Us" } }
        language: { code: { eq: RO } }
      ) {
        id
        contactPageFields {
          imagine {
            sourceUrl
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Contact Us" } }
        language: { code: { eq: EN } }
      ) {
        id
        contactPageFields {
          imagine {
            sourceUrl
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return pages[language]?.contactPageFields || wpPageRo?.contactPageFields
}
